import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertCtrl: AlertController,
    private router: Router
  ) { }

  showErrorAlert(mes: any) {
    this.alertCtrl.create(
      {
        cssClass: 'my-custom-class',
        header: 'Error',
        message: mes,
        buttons: [
          'Ok'
        ]
      }
    ).then(alertEl => {
      alertEl.present();
    });
  }


  showSuccessAlert(mes: any) {
    this.alertCtrl.create(
      {
        cssClass: 'my-custom-class',
        header: 'Success',
        message: mes,
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              this.router.navigate['core/member/transaction/options'];
            }
          },
        ]
      }
    ).then(alertEl => {
      alertEl.present();
    });
  }
}
